<template>
  <PcView>
    <div class="pc-container">
      <div class="pc-header">
        <div class="logo">
          <img src="@/assets/mp-weixin/logo.png" class="h-5" />
        </div>
        <div class="current-tab">
          <div class="current-tab-margin">
            <a-menu
              v-model:selectedKeys="current"
              mode="horizontal"
              @click="changeMenuFn"
            >
              <a-menu-item
                :key="item.key"
                v-for="(item, index) in topNavigationMenuList"
              >
                <template #icon>
                  <mail-outlined />
                </template>
                {{ item.name }}
              </a-menu-item>
              <!-- <a-menu-item key="system" style="margin-left: 748px">
                <van-action-bar-icon
                  text=""
                  badge=""
                  @click="toSystemFn"
                >
                <img src="@/assets/person/xitong.png" class="w-5 h-5" />
                </van-action-bar-icon>
              </a-menu-item> -->
            </a-menu>
          </div>
        </div>
        <div>
          <!-- icon="/src/assets/person/xitong.png" -->
          <van-action-bar-icon text="" badge="" @click="toSystemFn">
            <img src="@/assets/person/xitong.png" class="w-5 h-5" />
          </van-action-bar-icon>
        </div>
        <div class="header-user">
          <img src="@/assets/common/my-a.png" class="w-5 h-5" />
          <div class="header-user-div">
            <p class="user-name">{{ userinfo?.user_name }}</p>
            <p class="user-id">ID：{{ userinfo?.user_id }}</p>
          </div>
          <div class="header-logout-pos">
            <div class="header-logout" @click="logoutFn">退出</div>
          </div>
        </div>
      </div>
      <!-- <div class="pc-content">
      <Home v-if="current[0] == 'home'"></Home>
      <Meeting v-if="current[0] == 'meeting'"></Meeting>
      <Analysis v-if="current[0] == 'analysis'"></Analysis>
    </div> -->
    </div>
  </PcView>
</template>
<script lang="ts" setup>
import { ref, onMounted, computed } from "vue";
import { onLoad } from "@dcloudio/uni-app";
import { usePageParams } from "@/hooks/usePageParams";
import { useUserStore } from "@/store/user";
import { storeToRefs } from "pinia";
import { clearLocalStore } from "@/utils/common";

const { checkPermission } = useUserStore();

const { userinfo, userRoleId, userRole } = storeToRefs(useUserStore());
const current = ref<string[]>(["home"]);

const topNavigationMenuList = computed(() => {
  const list = [
    {
      name: "首页",
      key: "home",
    },
    {
      name: "会议",
      key: "meeting",
      module: "会议",
    },
    {
      name: "计划",
      key: "poa",
      module: "POA计划",
    },
    {
      name: "我的提交",
      key: "my-submit",
      module: "我的提交",
    },
    {
      name: "分析",
      key: "analysis",
      module: "分析",
    },
    {
      name: "辖区会议",
      key: "area-meeting",
      module: "辖区会议",
    },
    {
      name: "我的审批",
      key: "my-approved",
      module: "我的审批",
    },
    {
      name: "项目管理",
      key: "project-manage",
      module: "项目",
    },
    {
      name: "待办事项",
      key: "todo",
      module: "待办事项",
    },
  ];
  if (userRole.value) {
    return list.filter((item) => {
      if (item.module) {
        return checkPermission(item.module);
      }
      return true;
    });
  }
  return list;
});

const changeMenuFn = (item: any) => {
  current.value = [item.key];
  console.log("item.key", item.key);
  if (item.key == "home") {
    uni.navigateTo({ url: "/pages/index/index" });
  }
  if (item.key == "meeting") {
    uni.navigateTo({ url: "/pages/meeting/index" });
  }
  if (item.key == "analysis") {
    uni.navigateTo({ url: "/pages/analysis/index" });
  }
  if (item.key == "todo") {
    uni.navigateTo({ url: "/pages/to-do/index" });
  }
  if (item.key == "system") {
    uni.navigateTo({ url: "/pages/system-message/index" });
  }
  if (item.key == "area-meeting") {
    uni.navigateTo({ url: "/pages/area-meeting/index" });
  }
  if (item.key == "poa") {
    uni.navigateTo({ url: "/pages/POA/list" });
  }
  if (item.key === "my-submit") {
    uni.navigateTo({ url: "/pages/POA/my-submitted" });
  }
  if (item.key === "my-approved") {
    uni.navigateTo({ url: "/pages/POA/my-approve" });
  }
  if (item.key === "project-manage") {
    uni.navigateTo({ url: "/pages/project-manage/index" });
  }
};
const toSystemFn = () => {
  current.value = [];
  uni.navigateTo({ url: "/pages/system-message/index" });
};
const logoutFn = () => {
  clearLocalStore();
  if (window.location.origin.indexOf("localhost") > -1) {
    uni.navigateTo({ url: "/pages/auth-test/index" });
  } else {
    uni.navigateTo({ url: "/pages/loginScan/index" });
  }
};
const isMr = computed(() => userRoleId.value == "MR");
// const pageParams = usePageParams<"type" | "tab" | "id">();
onMounted(() => {
  let urlHash = window.location.href;
  if (urlHash.indexOf("pages/to-do/index") > -1) {
    current.value = ["todo"];
  } else if (urlHash.indexOf("pages/analysis/index") > -1) {
    current.value = ["analysis"];
  } else if (
    urlHash.indexOf("pages/meeting/index") > -1 ||
    urlHash.indexOf("pages/meeting/stop") > -1
  ) {
    current.value = ["meeting"];
  } else if (urlHash.indexOf("pages/system-message/index") > -1) {
    current.value = ["system"];
  } else if (urlHash.indexOf("pages/area-meeting/index") > -1) {
    current.value = ["area-meeting"];
  } else if (urlHash == "pages/index/index") {
    current.value = ["home"];
  } else if (urlHash.indexOf("pages/meeting/index") > -1) {
    current.value = ["meeting"];
  }
});
</script>

<style lang="less" scoped>
/deep/ uni-page-wrapper {
  background-color: #f0f2f5;
  uni-page-body {
    height: 100%;
  }
}
.pc-container {
  width: 100%;
  // height: 75px;
  // height: 100%;
  .pc-header {
    display: flex;
    align-items: center;
    padding: 0 40px;
    background-color: #fff;
    .logo {
    }
    .current-tab {
      flex: 1;
      .current-tab-margin {
        width: 1100px;
        margin: 0 auto;
        :v-deep .ant-menu-item:hover:after,
        :v-deep .ant-menu-item-selected:after {
          display: none;
          border-bottom: none;
        }
      }
      .ant-menu-horizontal {
        border-bottom: none;
      }
    }
    .header-user {
      display: flex;
      align-items: center;
      position: relative;
      .header-user-div {
        margin-left: 10px;
        p {
          margin: 0;
          padding: 0;
          font-size: 12px;
          height: 16px;
          line-height: 16px;
        }
        .user-name {
          color: #000000;
        }
        .user-id {
          color: #b8b2b2;
        }
      }
      .header-logout-pos {
        display: none;
        width: 100%;
        padding-top: 15px;
        position: absolute;
        left: 0;
        bottom: -60px;
      }
      .header-logout {
        width: 100%;
        background-color: #ffffff;
        border: 1px solid #e5e7eb;
        color: #b8b2b2;
        padding: 10px;
        border-radius: 4px;
        cursor: pointer;
        position: relative;
        &:before {
          content: "";
          width: 10px;
          height: 10px;
          border-left: 1px solid #e5e7eb;
          border-top: 1px solid #e5e7eb;
          position: absolute;
          top: -5px;
          left: 50%;
          transform: rotate(45deg);
          z-index: 2;
          background-color: #ffffff;
          font-size: 14px;
        }
      }
      &:hover {
        .header-logout-pos {
          display: block;
        }
      }
    }
  }
  .pc-content {
    width: 1000px;
    margin: 0 auto;
    // background-color: #fff;
    height: calc(100% - 45px);
    overflow-x: hidden;
    overflow-y: auto;
  }
}
</style>
